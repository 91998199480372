<template>
  <v-app> 
    <transition>
      <router-view></router-view>
    </transition>        
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
<style>
.theme--light {
    font-family: 'Rajdhani', sans-serif !important;
    
}
.app{
    background-color: #f2f2f2;
}
th{
  font-weight: bold !important;
  font-size: 20px !important;
  color: #fff !important;
  background-color: #120740 !important;
}
tr{
  font-weight: bold !important;
  color: #000 !important;
  font-size: 20px !important;
}
</style>
